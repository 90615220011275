<template>
  <div>
    <s-dialog :open="true" to="confirm-dialog">
      <s-dialog-overlay />
      <s-dialog-panel class="min-h-[18.8rem]">
        <st-dialog-header v-if="isCloseButtonShown" @clickClose="$emit('close')" />
        <s-dialog-content class="mt-auto">
          <s-dialog-content-body>
            <safe-html
              v-if="title"
              tag="p"
              :class="`text-${align ?? 'center'} mb-24 text-${titleColor ?? ''} font-${
                titleFontWeight ?? 'regular'
              }`"
              :html="title"
            />
            <template v-if="Array.isArray(content)">
              <div
                v-for="(item, index) in content"
                :key="`dialog_${index}`"
                :class="`py-1 text-${align ?? 'center'}`"
              >
                <safe-html tag="span" :html="item" />
              </div>
            </template>
            <!-- string -->
            <safe-html v-else tag="p" :class="`text-${align ?? 'center'}`" :html="content" />
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button
            v-if="isCancelButtonVisible"
            :class="cancelClasses"
            :variant="cancelVariant"
            @click="$emit('close', false)"
          >
            {{ cancelLabel || $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button
            :severity="severity"
            :class="[confirmClasses]"
            :variant="confirmVariant"
            @click="$emit('close', true)"
          >
            {{ confirmLabel || $t('studio.common.popup_case_cf_btn') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>
    <s-portal-target name="confirm-dialog" />
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';

import SafeHtml from '@/components/common/safe-html.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import type { SeverityType, VariantType } from '@/types/app.type';

const props = defineProps<{
  severity: SeverityType;
  content: string | string[];
  confirmLabel?: string;
  confirmClasses?: string;
  isCancelButtonVisible?: boolean;
  cancelLabel?: string;
  cancelClasses?: string;
  confirmVariant?: VariantType;
  cancelVariant?: VariantType;
  align?: 'center' | 'left' | 'right';
  isCloseButtonShown?: boolean;
  title?: string;
  titleColor?: string;
  titleFontWeight?: string;
}>();

defineEmits<{
  close: [v?: boolean];
}>();

const {
  severity,
  content,
  confirmLabel,
  confirmClasses,
  isCancelButtonVisible,
  cancelLabel,
  cancelClasses,
  title
} = toRefs(props);
</script>
