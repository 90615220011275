<template>
  <div class="flex gap-24 mt-40">
    <div class="flex-1">
      <st-box>
        <h3 class="text-xl font-bold text-on-surface-elevation-2 leading-lg">
          {{ title }}
        </h3>
        <div class="mt-8 flex flex-col gap-20 p-20 rounded-3xl bg-[var(--stds-glob-color-gray20)]">
          <dl class="flex flex-col gap-4">
            <dt class="text-sm leading-md text-on-surface-elevation-4 font-medium">
              {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_adj_rating') }}
            </dt>
            <dd
              class="flex gap-4 items-center text-md leading-lg font-medium text-on-surface-elevation-2"
            >
              {{
                $t(getAgeRatingText(currentRating?.ageRating), { rating: currentRating?.ageRating })
              }}
              <s-icon icon="ic-v2-control-long-arrow-right-line" size="xl" class="flex" />
              <span class="text-tint-red-a400">
                {{
                  changeMessage ||
                    $t(getAgeRatingText(rating?.additionalRating.ageRating), {
                      rating: rating?.additionalRating.ageRating
                    })
                }}
              </span>
            </dd>
          </dl>
          <dl class="flex flex-col gap-4">
            <dt class="text-sm leading-md text-on-surface-elevation-4 font-medium">
              {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_adj_category') }}
            </dt>
            <dd
              class="flex gap-4 items-center text-md leading-lg font-medium text-on-surface-elevation-2"
            >
              {{
                translateList(
                  convertRatingContentInfosToTranslationText(currentRating?.contentInfos)
                )
              }}
              <s-icon icon="ic-v2-control-long-arrow-right-line" size="xl" class="flex" />
              <span class="text-tint-red-a400">
                {{
                  changeMessage ||
                    translateList(
                      convertRatingContentInfosToTranslationText(
                        rating?.additionalRating.contentInfos
                      )
                    )
                }}
              </span>
            </dd>
          </dl>
          <dl class="flex flex-col gap-4">
            <dt class="text-sm leading-md text-on-surface-elevation-4 font-medium">
              {{
                $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_adj_applied_date')
              }}
            </dt>
            <dd
              class="flex gap-4 items-center text-md leading-lg font-medium text-on-surface-elevation-2"
            >
              {{ getDateTimeByLocale(rating?.additionalRating.effectiveAt ?? '') }}
            </dd>
          </dl>
          <dl class="flex flex-col gap-4">
            <dt class="text-sm leading-md text-on-surface-elevation-4 font-medium">
              {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_adj_reason') }}
            </dt>
            <dd
              class="flex gap-4 items-center text-md leading-lg font-medium text-on-surface-elevation-2"
            >
              {{ rating?.additionalRating.reason }}
            </dd>
          </dl>
        </div>
        <div
          v-if="isTextAreaShown"
          :class="
            isFullScreen
              ? 'fixed !w-full !h-full !max-h-[initial] bg-white top-0 left-0 z-[9999] pl-32 pr-32'
              : ''
          "
        >
          <st-form-title
            :formTitle="
              $t(
                'studio.prj_prod_mngmt.rating_build_review.self_review.rating_cancel_appeal_detail'
              )
            "
            required
            class="mt-32"
          >
            <s-button
              size="xs"
              variant="outline"
              icon="ic-v2-media-av-fullscreen-fill"
              @click="isFullScreen = !isFullScreen"
            >
              {{
                isFullScreen
                  ? $t('studio.prj_prod_mngmt.prod_home.create_term.btn_exit_fullscr')
                  : $t('studio.prj_prod_mngmt.prod_home.create_term.btn_input_fullscr')
              }}
            </s-button>
          </st-form-title>
          <st-textarea
            name="explanation"
            :rules="{
              required: $t(
                'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reject_appeal_required_msg'
              ),
              max_length: {
                length: 10000,
                message: $t('studio.common.def_key.exceed', {
                  length: formatNumberMultipleWithCommas(10000)
                })
              }
            }"
            :disabled="disabled"
            :placeholder="$t('studio.rating_build_review.reason_input_place_holder')"
            maxLength="10000"
            :containerClass="isFullScreen ? 'h-[calc(100vh-8rem)]' : 'mt-16 h-240'"
          />
        </div>
      </st-box>
      <div v-if="!viewOnly" class="mt-40 flex justify-center gap-16">
        <s-button
          v-if="isTextAreaShown"
          variant="primary"
          size="lg"
          class="!min-w-160"
          :disabled="disabled"
          @click="applyExplanation"
        >
          {{
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_cancel_appeal_req_btn')
          }}
        </s-button>
        <template v-else>
          <s-button variant="secondary" size="lg" class="!min-w-160" @click="onReport">
            {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.report_change_btn') }}
          </s-button>
          <s-button variant="primary" size="lg" class="!min-w-160" @click="acceptRating">
            {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_adj_accept_btn') }}
          </s-button>
        </template>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { fetchCompanyStatusApi } from '@/apis/business-bank-info.api';
import {
  acceptRatingApi,
  applyExplanationApi,
  fetchExplanationApi,
  updateContentModification
} from '@/apis/rating.api';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import ConfirmDialog from '@/components/rating/dialog/confirm-dialog.vue';
import StTextarea from '@/components/validation/textarea.vue';
import { showAlert, showDialog } from '@/composables/useDialog';
import { PRODUCT_RATING_URL } from '@/constants/url.const';
import { BusinessReviewStatus } from '@/enums/business.enum';
import { RatingStatus, RatingTypeStatus } from '@/enums/rating.enum';
import type { RatingProductResponse, ReleasedRatings } from '@/types/rating/rating.response.type';
import { redirectTo } from '@/utils/common.util';
import { formatNumberMultipleWithCommas } from '@/utils/currency.util';
import { getDateTimeByLocale } from '@/utils/date.util';
import { convertRatingContentInfosToTranslationText, getAgeRatingText } from '@/utils/rating.util';

const props = defineProps<{
  rating?: RatingProductResponse;
  isTextAreaShown: boolean;
  title: string;
  changeMessage?: string;
  disabled?: boolean;
  viewOnly?: boolean;
}>();

const route = useRoute();
const { t } = useI18n();

const productNo = route.params.productId as string;
const groupId = route.params.groupId as string;

const isFullScreen = ref<boolean>(false);

const { validate, meta, values, setFieldValue } = useForm<{
  explanation: string;
}>({
  initialValues: {
    explanation: ''
  }
});

const onReport = async () => {
  const companyStatus = await fetchCompanyStatusApi(groupId);

  if (!companyStatus || (companyStatus && companyStatus === BusinessReviewStatus.Request)) {
    showAlert({
      content: t('studio.prj_prod_mngmt.rating_build_review.self_review.under_review_change_x')
    });

    return;
  }

  const res = await showDialog({
    component: shallowRef(ConfirmDialog),
    props: {
      isCancelButtonVisible: true,
      titleColor: '[#333333]',
      titleFontWeight: 'bold',
      cancelVariant: 'outline',
      title: t('studio.prj_prod_mngmt.rating_build_review.self_review.reject_adj_change_msg1'),
      content: [t('studio.prj_prod_mngmt.rating_build_review.self_review.reject_adj_change_msg2')]
    }
  });

  if (res) {
    updateContentModification(productNo, {
      ratingType:
        props.rating?.releasedRatings[0]?.type ?? RatingTypeStatus.RECLASSIFICATION_COMMITTEE
    });

    await showAlert({
      content: t('studio.prj_prod_mngmt.rating_build_review.self_review.report_change_guide_popup')
    });
    redirectTo(PRODUCT_RATING_URL);
  }
};

const applyExplanation = async () => {
  await validate();
  if (!meta.value.valid) {
    return;
  }

  const status = await fetchCompanyStatusApi(groupId);
  if (!status) {
    showAlert({
      content: t('studio.prj_prod_mngmt.rating_build_review.self_review.popup_no_info')
    });

    return;
  }

  if (status === BusinessReviewStatus.Request) {
    await showAlert({
      content: t('studio.prj_prod_mngmt.rating_build_review.self_review.under_review_reg_x')
    });

    redirectTo(PRODUCT_RATING_URL);
    return;
  }

  try {
    await applyExplanationApi(productNo, {
      explanation: values.explanation
    });

    await showAlert({
      content: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.rating_cancel_appeal_req_done_popup'
      )
    });

    redirectTo(PRODUCT_RATING_URL);
  } catch (error) {}
};

const acceptRating = async () => {
  const res = await showDialog({
    component: shallowRef(ConfirmDialog),
    props: {
      isCancelButtonVisible: true,
      titleColor: 'brand-primary',
      cancelVariant: 'outline',
      titleFontWeight: 'bold',
      title: t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_adj_accepted_msg1'),
      content: t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_adj_accepted_msg2')
    }
  });

  if (res) {
    try {
      const accepted = await acceptRatingApi(productNo, {
        ratingType:
          (props.rating?.releasedRatings[0]?.type as RatingTypeStatus) ??
          RatingTypeStatus.RECLASSIFICATION_COMMITTEE
      });

      if (accepted) {
        await showAlert({
          content:
            (props.rating?.releasedRatings[0]?.type as RatingTypeStatus) ===
            RatingTypeStatus.RECLASSIFICATION
              ? t(
                'studio.prj_prod_mngmt.rating_build_review.self_review.rating_adj_accepted_cf_msg'
              )
              : t('studio.prj_prod_mngmt.rating_build_review.self_review.auth_adj_accepted_cf_msg')
        });

        redirectTo(PRODUCT_RATING_URL);
      }
    } catch (error) {
      // console.log(error);
    }
  }
};

const translateList = (contentInfos: string[]) => {
  const list = contentInfos.map((contentInfo: string) => {
    return t(contentInfo);
  });

  if (list.length === 0) {
    return t('studio.rating_review.included_content_none');
  }

  return list.join(', ');
};

const currentRating = computed(() => {
  if (props.rating) {
    return Array.isArray(props.rating.rating) ? props.rating.rating[0] : props.rating.rating;
  }

  return null;
});

const init = async () => {
  if ((props.rating?.rating as ReleasedRatings[])[0]?.status === RatingStatus.EXPLAINING) {
    const res = await fetchExplanationApi(productNo);
    setFieldValue('explanation', res?.explanation ?? '');
  }
};

init();
</script>
